// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_IUkyv{display:flex;flex-direction:column;flex-grow:1;height:100%;padding:1rem;width:100%}.map_PfjQT{-webkit-clip-path:inset(0 0 0 0 round var(--border-radius-large));clip-path:inset(0 0 0 0 round var(--border-radius-large));display:flex;justify-content:center;width:25%}.mapBtn_COfx7{align-self:center;position:absolute;z-index:1}.double_gwdE0,.thirds_jFZva{display:flex;width:100%}.noOwner_0We5C{margin:.5rem}.height_ScpJR{display:flex;flex-direction:column;max-height:3rem;min-height:3rem}.ownerHeight_XTUKz{align-items:center;display:flex;max-height:4rem;min-height:4rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_IUkyv",
	"map": "map_PfjQT",
	"mapBtn": "mapBtn_COfx7",
	"double": "double_gwdE0",
	"thirds": "thirds_jFZva",
	"noOwner": "noOwner_0We5C",
	"height": "height_ScpJR",
	"ownerHeight": "ownerHeight_XTUKz"
};
module.exports = ___CSS_LOADER_EXPORT___;
